<template>
  <div>
    <b-container class="container-content">
      <b-row class="justify-content-center">
        <b-col class="px-4 card-container shadow-lg text-center w-100">
          <img src="./../assets/images/blue_clock.png" alt="" class="pb-4" />
          <h4 class="text-primary my-2">
            {{ header }}
          </h4>
          <p class="my-4">
            {{ text }}
          </p>

          <b-button variant="primary" @click="goHome">{{ button }}</b-button>
          <!-- <b-spinner
          style="width: 3rem; height: 3rem;"
          :variant="loadingVariant"
          label="Large Spinner"
        ></b-spinner>
        <p class="mt-4">{{ $t("form_label.please_wait") }}...</p> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// let liff = window.liff;
export default {
  data() {
    return {
      header: null,
      text: null,
      button: null,
      isCreated: false,
    };
  },

  mounted() {
    // console.log("this.$i18n.locale === 'en'", this.$i18n.locale === "en");
    console.log("this.$i18n.locale === 'en'", this.$i18n.locale === "en");
    this.$i18n.locale === "en"
      ? (this.header = "Your booking request has been cancelled (Time ran out)")
      : (this.header = "การจ่ายเงินของคุณถูกยกเลิก (หมดเวลาการจ่ายเงิน)");
    this.$i18n.locale === "en"
      ? (this.text =
          "Your booking request has not be recorded. Please book again.")
      : (this.text = "การจองของคุณยังไม่ได้ถูกบันทึก โปรดจองใหม่อีกครั้ง");
    this.$i18n.locale === "en" ? (this.button = "OK") : (this.button = "ตกลง");
    // location.reload();
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  background-color: #fff;
  border-radius: 8px;
  min-height: fit-content !important;
  padding: 2rem;
  overflow-y: hidden;
}
</style>
